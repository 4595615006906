import { adminClient } from "@api/admin/AdminClient";
import InformationDialogComponent from "@components/admin/InformationDialogComponent";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@material-ui/core";
import { AdminProfileSettingsAddressModel } from "@reshopper/admin-client";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function ActiveUserCountWithinAreaDialogComponent(
    props: {
        address?: AdminProfileSettingsAddressModel,
        confirmationDialogOpen: boolean,
        onClose: () => void
    }
): JSX.Element {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isRadiusValid, setIsRadiusValid] = useState(true);
    const [activeUsersCount, setActiveUsersCount] = useState<number>();
    const [street, setStreet] = useState(props.address ? props.address.street : "");
    const [city, setCity] = useState(props.address ? props.address.city : "");
    const [zipcode, setZipcode] = useState(props.address ? props.address.zipCode : "");
    const [radiusInKm, setRadiusInKm] = useState(20);

    async function getActiveUsersWithinArea(
        city: string,
        zipcode: string,
        radiusInKm: number
    ) {
        await performAction(
            async () => {
                const activeUsersResponse = await adminClient().adminUserActiveUsersWithinAreaPost({
                    street,
                    city,
                    zipcode,
                    radiusInKm
                });
                setActiveUsersCount(activeUsersResponse.result);
            },
            "Failed to count active users within area",
            false);
        setDialogOpen(true);
    }

    function ActiveUsersCountDialogComponent() {
        //if the current user has an address, and we clicked "get active users within area of user", then the current user would have been included in the count.
        //since we don't want that, we subtract it by one.
        var activeUsersCountAdjusted = props.address && activeUsersCount ?
            activeUsersCount - 1 :
            activeUsersCount;

        return <InformationDialogComponent
            isDialogOpen={dialogOpen}
            onDismiss={() => {
                setDialogOpen(false);
            }}
            dialogTitle={activeUsersCountAdjusted + " active users within area"} />;
    }

    return <>
        <Dialog
            open={props.confirmationDialogOpen}
            transitionDuration={0}
            disableAutoFocus={false}
            disableRestoreFocus={false}
        >
            <DialogTitle>
                Get active user count within area
      </DialogTitle>
            <DialogContent>
                <FormControl>
                    <TextField
                        id="street"
                        label="Street"
                        value={street}
                        onChange=
                        {(event) => {
                            setStreet(event.target.value);
                        }}
                    />
                    <TextField
                        id="city"
                        label="City"
                        defaultValue={city}
                        onChange=
                        {(event) => {
                            setCity(event.target.value);
                        }}
                    />
                    <TextField
                        id="zipcode"
                        label="Zipcode"
                        defaultValue={zipcode}
                        onChange=
                        {(event) => {
                            setZipcode(event.target.value);
                        }}
                    />
                    <TextField
                        error={!isRadiusValid}
                        helperText={isRadiusValid ? "" : "Radius is not a valid number"}
                        id="radius"
                        label="Radius"
                        defaultValue={radiusInKm}
                        onChange={(event) => {
                            var radiusInKmAsNumber = +event.target.value;
                            if (isNaN(radiusInKmAsNumber)) {
                                setIsRadiusValid(false);
                                return;
                            }
                            setRadiusInKm(radiusInKmAsNumber);
                            setIsRadiusValid(true);
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                    variant="outlined"
                    color="primary">
                    Cancel
        </Button>
                <Button
                    disabled={!isRadiusValid}
                    onClick={() => getActiveUsersWithinArea(
                        city,
                        zipcode,
                        radiusInKm
                    )}
                    variant="contained"
                    color="primary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
        <ActiveUsersCountDialogComponent />
    </>

}