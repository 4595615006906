import { adminClient } from "@api/admin/AdminClient";
import InternalLink from "@components/InternalLink";
import { queryApi } from "@hooks/api";
import { useAsyncEffect } from "@hooks/miscellaneous";
import { Button, FormControlLabel, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { AdminTopTradersResponse, TradeCountAggregationSortField } from "@reshopper/admin-client";
import { PlusType } from "@reshopper/web-client";
import { formatNumber } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { toPascalCase } from "@utils/strings";
import { useState } from "react";

export default function UserTopTradersTableComponent() {
	const [hasUsersBeenRequested, setHasUsersBeenRequested] = useState(false);
	const [updateTable, setUpdateTable] = useState<boolean>(false);
	const [pageSize, setSize] = useState(10);
	const [offset, setOffset] = useState(0);
	const [plusType, setPlusType] = useState<string>("All");
	const [zipCode, setZipCode] = useState<string>();
	const [shouldOnlyGetSoldCount, setShouldOnlyGetSoldCount] = useState<boolean>(false);
	const [monthNumber, setMonthNumber] = useState<number>();
	const [yearNumber, setYearNumber] = useState<number>();
	const [topTraderResponse, setTopTraderResponse] = useState<AdminTopTradersResponse[] | null>(null);
	const [plusTypes] = queryApi(
		async (options) => await adminClient().adminUserSubscriptionPlusTypesGet(options),
		[]);
	const [sortField, setSortField] = useState<TradeCountAggregationSortField>("purchaseCount");

	function getCurrentPage() {
		return Math.floor(offset / pageSize);
	}

	async function fetchPage(pageNumber: number) {
		let newOffset = pageNumber * pageSize;
		setOffset(newOffset);
		setTopTraderResponse(null);

		var topTraders = await adminClient().adminUserTopTradersPost({
			body: {
				sortField: sortField,
				pageSize: pageSize,
				offset: newOffset,
				plusType: plusType === "All" ? undefined : plusType as PlusType,
				zipCode,
				shouldOnlyGetSoldCount,
				monthNumber,
				yearNumber
			}
		});

		setTopTraderResponse(topTraders);
	}

	useAsyncEffect(async () => {
		if (!hasUsersBeenRequested) {
			return;
		}
		setSortField(shouldOnlyGetSoldCount ? "soldCount" : "purchaseCount")
		await fetchPage(0)
	}, [pageSize, sortField, plusType, updateTable, hasUsersBeenRequested]);


	async function onPlusTypeChange(newPlusType: string) {
		setPlusType(newPlusType);
	}


	async function changeSortField(newSortField: TradeCountAggregationSortField) {
		if (newSortField === sortField) {
			return;
		}
		setSortField(newSortField);
		setOffset(0);
	}

	function renderTableTitle() {
		return <>
			<Typography variant="h5">Top Traders</Typography>
		</>
	}

	function renderTableSelect() {
		return <Select
			style={{
				marginTop: 16,
				marginRight: 16
			}}
			native={false}
			value={plusType}
			onChange={(e) => onPlusTypeChange(e.target.value as string)}>
			<MenuItem value={"All"}>
				All
			</MenuItem>
			{plusTypes && plusTypes?.map((p, index) => {
				return <MenuItem
					value={p.plusType.toString()}
					key={index}>
					{toPascalCase(p.plusType)}
				</MenuItem>;
			})}
		</Select>;
	}

	function renderTableHead() {
		return <>
			<TableHead>
				<TableRow>
					<TableCell>User</TableCell>
					<TableCell>Plus Type</TableCell>
					<TableCell>Last Session</TableCell>
					<TableCell
						key="SoldCount"
					>
						<TableSortLabel
							active={sortField === "soldCount"}
							direction={"desc"}
							onClick={() => changeSortField("soldCount")}
						>
						</TableSortLabel>
						Items Sold
					</TableCell>
					<TableCell
						key="PurchaseCount"

					>
						<TableSortLabel
							active={sortField === "purchaseCount"}
							direction={"desc"}
							onClick={() => changeSortField("purchaseCount")}
						>
						</TableSortLabel>
						Items Purchased
					</TableCell>
					<TableCell>Total revenue (incl. VAT)</TableCell>
					<TableCell>Fees revenue (incl. VAT)</TableCell>
					<TableCell>Credits revenue (incl. VAT)</TableCell>
					<TableCell>Subscription revenue (incl. VAT)</TableCell>
				</TableRow>
			</TableHead>
		</>;
	}

	function renderUserRow(response: AdminTopTradersResponse) {
		let user = response.user;
		let revenue = response.revenue;
		return <TableRow
			key={user.id}
		>
			<TableCell>
				<InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(user.id)}>
					{user.firstName + " " + user.lastName}
				</InternalLink>
			</TableCell>
			<TableCell>{
				user.plusType ?
					user.plusType.toString() :
					"No plustype"}</TableCell>
			<TableCell>{toDanishDateFormat(user.lastSessionUtc)}</TableCell>
			<TableCell>{formatNumber(user.soldCount)}</TableCell>
			<TableCell>{user.purchaseCount ? formatNumber(user.purchaseCount) : null}</TableCell>
			<TableCell>{revenue ? formatNumber((
				revenue.feesRevenueInHundreds +
				revenue.creditsRevenueInHundreds +
				revenue.subscriptionsRevenueInHundreds) / 100) : null} DKK</TableCell>
			<TableCell>{revenue ? formatNumber(revenue.feesRevenueInHundreds / 100) : null} DKK</TableCell>
			<TableCell>{revenue ? formatNumber(revenue.creditsRevenueInHundreds / 100) : null} DKK</TableCell>
			<TableCell>{revenue ? formatNumber(revenue.subscriptionsRevenueInHundreds / 100) : null} DKK</TableCell>
		</TableRow>
	}

	function insertSkeletonForList(): React.ReactNode {
		let elements = [];

		let headersCount = 9;
		for (let index = 0; index < pageSize; index++) {
			elements.push(
				<TableRow key={index} style={{
					height: 45
				}}>
					<TableCell colSpan={headersCount}>
						<Skeleton height={20} />
					</TableCell>
				</TableRow>
			);
		}

		return <>
			{elements}
		</>
	}

	function renderFetchButton() {
		return <Button
			onClick={() => {
				setHasUsersBeenRequested(true);
				setUpdateTable((oldUpdateTable) => !oldUpdateTable);
			}}
			variant="contained"
			color="primary"
		>
			Fetch top traders
		</Button>;
	}


	const renderTableFunctions = () => {
		return <>
			{renderTableTitle()}
			{renderTableSelect()}
			<TextField
				label="ZipCode"
				onChange={(e) => setZipCode(e.target.value)} />
			<TextField
				label="Month"
				onChange={(e) => setMonthNumber(+e.target.value)} />
			<TextField
				label="Year"
				onChange={(e) => setYearNumber(+e.target.value)} />
			<FormControlLabel
				label="Only sold count"
				disabled={!monthNumber}
				control={<Switch
					checked={shouldOnlyGetSoldCount}
					onChange={(e) => {
						setShouldOnlyGetSoldCount(e.target.checked)
						}} />} />
			{renderFetchButton()}
		</>
	};

	if (!hasUsersBeenRequested) {
		return renderTableFunctions();
	}

	if (topTraderResponse?.length === 0) {
		return <>
			{renderTableFunctions()}
			<TableContainer component={Paper}>
				<Table size="small">
					<caption>No entities found.</caption>
					{renderTableHead()}
					<TableBody>
					</TableBody>
				</Table>
			</TableContainer>
		</>;
	}


	return <>
		{renderTableFunctions()}
		<TableContainer component={Paper}>
			<Table size="small">
				{renderTableHead()}
				<TableBody>
					{!topTraderResponse ? insertSkeletonForList() :
						<>
							{topTraderResponse?.map(response => renderUserRow(response))}
						</>}
				</TableBody>
			</Table>
			{topTraderResponse && topTraderResponse?.length > 0 &&
				<TablePagination
					rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
					count={10000}
					rowsPerPage={pageSize}
					page={getCurrentPage()}
					onPageChange={(_e, page) => fetchPage(page)}
					onChangeRowsPerPage={(e) => {
						const rowsPerPage = +e.target.value;
						setSize(rowsPerPage);
					}} />}

		</TableContainer>
	</>
}




