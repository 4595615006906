import { withAdminMenuLayout } from "@components/admin/Decorators";
import PageTitle from "@components/admin/PageTitle";
import UserTopTradersTableComponent from "@components/admin/users/details/UserTopTradersTableComponent";
import RatingListComponent from "@components/admin/users/recent/RatingListComponent";
import UserListComponent from "@components/admin/users/recent/UserListComponent";
import SearchComponent from "@components/SearchComponent";
import { Box } from "@material-ui/core";
import { useState } from "react";

export default withAdminMenuLayout(function RecentUsersPageComponent() {
    const [searchTerm, setSearchTerm] = useState("");

    async function getUserList() {
        window.location.href = ("/admin/users/search?query=" + encodeURIComponent(searchTerm));
    }

    return <>
        <PageTitle title="Users">
            <SearchComponent
                onSearchTermChanged={setSearchTerm}
                onSearch={getUserList}
                autoFocus={false} />
        </PageTitle>
        <Box>
            <UserListComponent />
        </Box>
        <Box style={{marginTop: "30px"}}>
            <RatingListComponent />
        </Box>  
		  <Box style={{marginTop: "30px"}}>
            <UserTopTradersTableComponent />
        </Box>
    </>
});