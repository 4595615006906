import { adminClient } from "@api/admin/AdminClient";
import InternalLink from "@components/InternalLink";
import { useAsyncEffect } from "@hooks/miscellaneous";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import { Skeleton } from "@material-ui/lab";
import { AdminUserWithUserRatingStatisticResponse, AdminUserWithUserRatingStatisticResponsesResult, RatingSortField } from "@reshopper/admin-client";
import { addPlusIfPositiveNumber } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useState } from "react";

function renderBlocked(blocked: boolean) {
	if (blocked)
		return <Tooltip title="User is blocked">
			<BlockIcon
				color="error"
				style={{
					position: "relative",
					top: "-1px",
					verticalAlign: "middle",
					fontSize: "0.9rem",
					marginLeft: "3px"
				}} />
		</Tooltip>
}

export default function RatingListComponent() {
	const [size, setSize] = useState(10);
	const [offset, setOffset] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [mainRatingSortField, setMainRatingSortField] = useState<RatingSortField>("ratingsAverage");
	const [secondaryRatingSortField, setSecondaryRatingSortField] = useState<RatingSortField | undefined>("ratingsSum");
	const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
	const [userWithUserRatingStatisticResponsesResult, setUserWithUserRatingStatisticResponsesResultlt] = useState<AdminUserWithUserRatingStatisticResponsesResult | null>(null);

	function getCurrentPage() {
		return Math.floor(offset / size);
	}

	async function fetchPage(pageNumber: number) {
		let newOffset = pageNumber * size;
		setOffset(newOffset);
		setUserWithUserRatingStatisticResponsesResultlt(null);

		let userRatingStatisticResponsesResult = await adminClient().adminRatingsUserRatingStatisticsGet({
			pageSize: size,
			offset: newOffset,
			mainRatingSortField: mainRatingSortField,
			secondaryRatingSortField: secondaryRatingSortField,
			sortDirection: sortDirection
		});

		setUserWithUserRatingStatisticResponsesResultlt(userRatingStatisticResponsesResult);
		setTotalCount(userRatingStatisticResponsesResult.totalCount);
	}

	useAsyncEffect(async () => await fetchPage(0), [size, mainRatingSortField, sortDirection]);

	function onClickSortField(selectedSortField: RatingSortField) {
		if (selectedSortField === mainRatingSortField) {
			changeSortDirection();
		} else {
			setMainRatingSortField(selectedSortField);
			if (selectedSortField === "ratingsSum") {
				setSecondaryRatingSortField("ratingsAverage");
			} else if (selectedSortField === "ratingsAverage") {
				setSecondaryRatingSortField("ratingsSum");
			} else if (selectedSortField === "ratingsCount")
				setSecondaryRatingSortField("ratingsAverage");
			else {
				setSecondaryRatingSortField(undefined);
			}
			setOffset(0);
		}
	}

	function changeSortDirection() {
		setSortDirection(sortDirection === "asc" ? "desc" : "asc");
		setOffset(0);
	}

	function renderRatingRow(userWithUserRatingStatisticResponse: AdminUserWithUserRatingStatisticResponse) {
		const address = userWithUserRatingStatisticResponse.addressResponse;
		return <TableRow
			hover={true}
			key={userWithUserRatingStatisticResponse.id}>
			<TableCell
				align="left"
				style={{ paddingLeft: 8, paddingRight: 0 }}
			>
				<InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(userWithUserRatingStatisticResponse.id)}>
					{userWithUserRatingStatisticResponse.name}
				</InternalLink>
				{renderBlocked(!!userWithUserRatingStatisticResponse.blockedUtc)}
			</TableCell>
			{renderLeftAlignedTableCell(address?.city + ", " + address?.zipCode)}
			{renderLeftAlignedTableCell(toDanishDateFormat(userWithUserRatingStatisticResponse.lastSessionUtc, "Pp"))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(userWithUserRatingStatisticResponse.userRatingStatisticResponse.respondTimeSum))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(userWithUserRatingStatisticResponse.userRatingStatisticResponse.agreementsSum))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(userWithUserRatingStatisticResponse.userRatingStatisticResponse.itemDescriptionSum))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(userWithUserRatingStatisticResponse.userRatingStatisticResponse.shippingHandInTimeSum))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(userWithUserRatingStatisticResponse.userRatingStatisticResponse.communicationSum))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(userWithUserRatingStatisticResponse.userRatingStatisticResponse.photosSum))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(userWithUserRatingStatisticResponse.userRatingStatisticResponse.fixedPriceRespectSum))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(userWithUserRatingStatisticResponse.userRatingStatisticResponse.ratingsSum))}
			{renderLeftAlignedTableCell(addPlusIfPositiveNumber(parseFloat(userWithUserRatingStatisticResponse.userRatingStatisticResponse.ratingsAverage.toFixed(2))))}
			{renderLeftAlignedTableCell(userWithUserRatingStatisticResponse.userRatingStatisticResponse.ratingsCount.toString())}
		</TableRow>
	}

	function renderLeftAlignedTableCell(
		content: string,
		paddingLeftOverride?: number) {
		return <TableCell
			align="left"
			style={{
				paddingLeft: paddingLeftOverride ?? 0,
				paddingRight: 0
			}}
		>
			{content}
		</TableCell>
	}

	function renderTableHeader() {
		return <TableHead>
			<TableRow>
				{renderLeftAlignedTableCell("Name", 8)}
				{renderLeftAlignedTableCell("City")}
				{renderLeftAlignedTableCell("Last Session")}
				{renderTableColumnLabel("RespondTime", "respondTimeSum")}
				{renderTableColumnLabel("Agreements", "agreementsSum")}
				{renderTableColumnLabel("Description", "itemDescriptionSum")}
				{renderTableColumnLabel("HandInTime", "shippingHandInTimeSum")}
				{renderTableColumnLabel("Communication", "communicationSum")}
				{renderTableColumnLabel("Photos", "photosSum")}
				{renderTableColumnLabel("Fixed Price", "fixedPriceRespectSum")}
				{renderTableColumnLabel("Total", "ratingsSum")}
				{renderTableColumnLabel("Average", "ratingsAverage")}
				{renderTableColumnLabel("Count", "ratingsCount")}
			</TableRow>
		</TableHead>
	}

	function insertSkeletonForRatingsList(): React.ReactNode {
		let elements = [];

		let headersCount = 13;
		for (let index = 0; index < size; index++) {
			elements.push(
				<TableRow key={index} style={{
					height: 45
				}}>
					<TableCell colSpan={headersCount}>
						<Skeleton height={20} />
					</TableCell>
				</TableRow>
			);
		}

		return <>
			{elements}
		</>
	}

	function renderTableColumnLabel(
		label: string,
		sortField: RatingSortField
	) {
		return <TableCell
			style={{ padding: 0 }}
			sortDirection={sortDirection}
			align="left"
		>
			{label}
			<TableSortLabel
				active={mainRatingSortField === sortField}
				direction={sortDirection}
				onClick={() => onClickSortField(sortField)}
			>
			</TableSortLabel>
		</TableCell>;
	}

	let userWithUserRatingStatisticResponse = userWithUserRatingStatisticResponsesResult?.userWithUserRatingStatisticResponse;


	if (userWithUserRatingStatisticResponse?.length === 0) {
		return <>
			<Typography variant="h5"
				style={{ marginBottom: 16 }}
			>
				Ratings
			</Typography>
			<Paper>
				<TableContainer>
					<Table size="small">
						<caption>No entities found.</caption>
						{renderTableHeader()}
						<TableBody>
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>;
	}

	return <>
		<Typography variant="h5"
			style={{ marginBottom: 16 }}
		>
			Ratings
		</Typography>
			<TableContainer component={Paper} style={{ maxHeight: "50vh" }}>
				<Table size="small" stickyHeader>
					{renderTableHeader()}
					<TableBody>
						{!userWithUserRatingStatisticResponse ? insertSkeletonForRatingsList() :
							<>
								{userWithUserRatingStatisticResponse.map(userRatingStatisticResponse => renderRatingRow(userRatingStatisticResponse))}
							</>}
					</TableBody>
				</Table>
				{userWithUserRatingStatisticResponse && userWithUserRatingStatisticResponse.length > 0 &&
				<TablePagination
					rowsPerPageOptions={[2, 5, 10, 25, 50, 100, 250]}
					count={totalCount}
					rowsPerPage={size}
					page={getCurrentPage()}
					onPageChange={(_e, page) => fetchPage(page)}
					onChangeRowsPerPage={(e) => {
						const rowsPerPage = +e.target.value;
						setSize(rowsPerPage);
					}} />}
			</TableContainer>
	</>;
}

