import ActiveUserCountWithinAreaDialogComponent from "@components/admin/users/ActiveUserCountWithinAreaDialogComponent";
import { Button } from "@material-ui/core";
import { useState } from "react";

export default function ActiveUserCountWithinAreaButtonComponent(

): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
  }

  return <>
    <Button
      onClick={handleOpen}
      variant="contained"
      color="secondary"
    >
      Active user count within area
    </Button>
    <ActiveUserCountWithinAreaDialogComponent
      confirmationDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
    />
  </>
}