import ActiveUserCountWithinAreaButtonComponent from "@components/admin/users/recent/ActiveUserCountWithinAreaButtonComponent";
import InternalLink from "@components/InternalLink";
import TableComponent, { Header } from "@components/TableComponent";
import TabsControl from "@components/TabsControl";
import WebpImage from "@components/WebpImage";
import { Box, FormControl, InputLabel, MenuItem, Select, TableCell } from "@material-ui/core";
import { AdminUserResponse } from "@reshopper/admin-client";
import { formatUserAddress } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useState } from "react";

export default function UserListComponent() {
  const [currentlySelectedCountry, setCurrentlySelectedCountry] = useState("");

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    setCurrentlySelectedCountry(event.target.value as string);
  }

  function renderUserRow(user: AdminUserResponse) {
    user.settings?.contactPhone?.phoneNumber
    return <>
      <TableCell>
        <WebpImage
          size={32}
          images={user.profileImages} />
      </TableCell>
      <TableCell>
        <InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(user.id)}>
          {user.firstName + " " + user.lastName}
        </InternalLink>
      </TableCell>
      <TableCell>
        {user.email}
      </TableCell>
      <TableCell>
        {!user.settings?.addresses?.length ?
          "No Address" :
          formatUserAddress(user.settings.addresses[0])}
      </TableCell>
      <TableCell>
        {user.settings?.contactPhone ? `(${user.settings?.contactPhone?.countryCode}) ${user.settings?.contactPhone?.phoneNumber}` : "No Phone Number"}
      </TableCell>
      <TableCell>
        {toDanishDateFormat(user.createdUtc)}
      </TableCell>
      <TableCell>
        {user.stats?.itemsCreated ?? 0}
      </TableCell>
      <TableCell>
        {user.shadowblockedAtUtc && toDanishDateFormat(user.shadowblockedAtUtc)}
      </TableCell>
    </>;
  }

  function getUserHeaders(): Array<Header<AdminUserResponse>> {
    return [
      {
        databaseSortingKey: null,
        label: ""
      },
      {
        databaseSortingKey: null,
        label: "Name"
      },
      {
        databaseSortingKey: x => x.email,
        label: "Email"
      },
      {
        databaseSortingKey: null,
        label: "Address"
      },
      {
        databaseSortingKey: null,
        label: "Phone Number"
      },
      {
        databaseSortingKey: x => x.createdUtc,
        label: "Created",
        isDefault: true
      },
      {
        databaseSortingKey: null,
        label: "Items Created"
      },
      {
        databaseSortingKey: x => x.shadowblockedAtUtc,
        label: "Shadow blocked at"
      }
    ];
  }

  function getShadowBlockedHeaders(): Array<Header<AdminUserResponse>> {
    return [
      {
        databaseSortingKey: null,
        label: ""
      },
      {
        databaseSortingKey: null,
        label: "Name"
      },
      {
        databaseSortingKey: x => x.email,
        label: "Email"
      },
      {
        databaseSortingKey: null,
        label: "Address"
      },
      {
        databaseSortingKey: null,
        label: "Phone Number"
      },
      {
        databaseSortingKey: x => x.createdUtc,
        label: "Created"
      },
      {
        databaseSortingKey: null,
        label: "Items Created"
      },
      {
        databaseSortingKey: x => x.shadowblockedAtUtc,
        label: "Shadow blocked at",
        isDefault: true
      }
    ]
  }

  return (
    <>

      <Box
        display="flex"
        flexDirection="reverse-row"
        alignItems="center"
        flexWrap="nowrap"
        justifyContent="flex-end"
        paddingBottom={2}
      >
        <Box>
        </Box>
        <Box flexGrow={1}>
        </Box>
        <Box>
          <FormControl
            fullWidth={true} style={{
              minWidth: "100px"
            }}>
            <InputLabel>
              Countries
            </InputLabel>
            <Select
              native={false}
              value={currentlySelectedCountry}
              onChange={handleChange}
            >
              <MenuItem value="">
                All
              </MenuItem>
              <MenuItem value="DK">
                DK
              </MenuItem>
              <MenuItem value="SE">
                SE
              </MenuItem>
              <MenuItem value="FO">
                FO
              </MenuItem>
              <MenuItem value="NL">
                NL
              </MenuItem>
              <MenuItem value="GB">
                GB
              </MenuItem>
              <MenuItem value="CA">
                CA
              </MenuItem>
              <MenuItem value="PL">
                PL
              </MenuItem>
              <MenuItem value="IS">
                IS
              </MenuItem>
            </Select>
          </FormControl>
          <Box paddingTop={2}>
            <ActiveUserCountWithinAreaButtonComponent />
          </Box>
        </Box>
      </Box>

      <TabsControl
        tabs={[
          {
            label: "Recent",
            content:
              <TableComponent<AdminUserResponse>
                url={"/admin/users/recent"}
                isSelectable={false}
                sortDirection="desc"
                size={10}
                additionalQueryParameters={{
                  country: currentlySelectedCountry
                }}
                headers={getUserHeaders()}
                renderRow={user => renderUserRow(user)}
              />
          },
          {
            label: "Monitored",
            content:
              <TableComponent<AdminUserResponse>
                url={"/admin/users/monitored"}
                isSelectable={false}
                sortDirection="desc"
                size={10}
                additionalQueryParameters={{
                  country: currentlySelectedCountry
                }}
                headers={getUserHeaders()}
                renderRow={user => renderUserRow(user)}
              />
          },
          {
            label: "Blocked",
            content:
              <TableComponent<AdminUserResponse>
                url={"/admin/users/blocked"}
                isSelectable={false}
                sortDirection="desc"
                size={10}
                additionalQueryParameters={{
                  country: currentlySelectedCountry
                }}
                headers={getUserHeaders()}
                renderRow={user => renderUserRow(user)}
              />
          },
          {
            label: "Shadow Blocked",
            content:
              <TableComponent<AdminUserResponse>
                url={"/admin/users/shadowblocked"}
                isSelectable={false}
                sortDirection="desc"
                size={10}
                additionalQueryParameters={{
                  country: currentlySelectedCountry
                }}
                headers={getShadowBlockedHeaders()}
                renderRow={user => renderUserRow(user)}
              />
          },
          {
            label: "Business",
            content:
              <TableComponent<AdminUserResponse>
                url={"/admin/users/business"}
                isSelectable={false}
                sortDirection="desc"
                size={10}
                additionalQueryParameters={{
                  country: currentlySelectedCountry
                }}
                headers={getUserHeaders()}
                renderRow={user => renderUserRow(user)}
              />
          }
        ]} />
    </>

  );

}